
import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import { requestNotificationPermission } from "./components/notificationUtils";

// Lazy load components
const RegistrationForm = React.lazy(() => import("./components/Register"));
const Login = React.lazy(() => import("./components/Login"));
const LandingPage = React.lazy(() => import("./components/LandingPage"));
const Logout = React.lazy(() => import("./components/Logout"));
const AdminLayout = React.lazy(() => import("./layouts/Admin_Layout"));
const AdminUsers = React.lazy(() => import("./components/Admin_Users"));
const ForgotPassword = React.lazy(() => import("./components/ForgotPassword"));
const AdminTaskToUser = React.lazy(() => import("./components/AdminTaskToUser"));
const TaskPage = React.lazy(() => import("./components/TaskPage"));
const AdminProjects = React.lazy(() => import("./components/AdminProjects"));
const UserProfile = React.lazy(() => import("./components/UserProfile"));
const Reports = React.lazy(() => import("./components/Reports"));
const AdminTaskList = React.lazy(() => import("./components/AdminTaskList"));
const AdminEditTask = React.lazy(() => import("./components/AdminEditTask"));
const ToDo = React.lazy(() => import("./components/ToDo"));
const Udash = React.lazy(() => import("./components/TicketApp/Udash"));
const AdminAlltickets = React.lazy(() => import("./components/AdminAlltickets"));
const SupportTeamMember = React.lazy(() => import("./components/SupportTeamMember"));
const TaskDetails = React.lazy(() => import("./components/TaskDetail"));
const UserTaskDetails = React.lazy(() => import("./components/UserTaskdetails"));
const ProjectDetails = React.lazy(() => import("./components/ProjectDetails"));
const KanbanBoard =React.lazy(()=>import("./components/KanbanBoard"))
const ProjectDashboard =React.lazy(()=>import("./components/ProjectDashboard"))


function App() {
  useEffect(() => {
    const askNotificationPermission = async () => {
      await requestNotificationPermission();
    };

    const prefetchCSS = () => {
      const stylesheets = [
        "/path/to/admin_layout.css",
        "/path/to/user_profile.css",
        // Add paths for other components' CSS files
      ];
      stylesheets.forEach((href) => {
        const link = document.createElement("link");
        link.rel = "prefetch";
        link.as = "style";
        link.href = href;
        document.head.appendChild(link);
      });
    };

    askNotificationPermission();
    prefetchCSS();
  }, []);

  return (
    <BrowserRouter>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/task"
              element={
                <PrivateRoute>
                  <TaskPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/task/usertaskdetailspage/:taskId"
              element={
                <PrivateRoute>
                  <UserTaskDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/taskdetails/:taskId"
              element={
                <PrivateRoute>
                  <TaskDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/todo"
              element={
                <PrivateRoute>
                  <ToDo />
                </PrivateRoute>
              }
            />
            <Route
              path="/raiseticket"
              element={
                <PrivateRoute>
                  <Udash />
                </PrivateRoute>
              }
            />
            <Route
              path="/kanbanboard"
              element={
                <PrivateRoute>
                  <KanbanBoard />
                </PrivateRoute>
              }
            />
            <Route
              path="/userprofile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/admin/"
              element={
                <PrivateRoute>
                  <AdminLayout />
                </PrivateRoute>
              }
            >
              <Route
                path="users"
                element={
                  <PrivateRoute>
                    <AdminUsers />
                  </PrivateRoute>
                }
              />
              <Route
                path="adminalltickets"
                element={
                  <PrivateRoute>
                    <AdminAlltickets />
                  </PrivateRoute>
                }
              />
              <Route
                path="projectdashboard"
                element={
                  <PrivateRoute>
                    <ProjectDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="supportteammembertivkets"
                element={
                  <PrivateRoute>
                    <SupportTeamMember />
                  </PrivateRoute>
                }
              />
              <Route
                path="prodetails"
                element={
                  <PrivateRoute>
                    <ProjectDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="taskToTeamMembers"
                element={
                  <PrivateRoute>
                    <AdminTaskToUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <PrivateRoute>
                    <Reports />
                  </PrivateRoute>
                }
              />
              <Route
                path="taskListdetails"
                element={
                  <PrivateRoute>
                    <AdminTaskList />
                  </PrivateRoute>
                }
              />
              <Route
                path="projects"
                element={
                  <PrivateRoute>
                    <AdminProjects />
                  </PrivateRoute>
                }
              />
              <Route
                path="edit-task/:taskId"
                element={
                  <PrivateRoute>
                    <AdminEditTask />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
