
import React, { createContext, useContext, useEffect, useState } from "react";
import { UseAuth } from "./auth.jsx"; // Access AuthContext for user and token

const API_URL = process.env.REACT_APP_API_URL;

// Create Notification Context
export const NotificationContext = createContext();

// Provider Component
export const NotificationProvider = ({ children }) => {
  const { user, authorizationToken } = UseAuth(); // Get user and token from Auth
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch Notifications
  const fetchNotifications = async () => {
    if (!user) return; // Ensure user is logged in
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/notifications/${user._id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authorizationToken}`, // Use Bearer format for token
        },
      });
      if (!response.ok) throw new Error("Failed to fetch notifications");
      const data = await response.json();
      setNotifications(data);
      setUnreadCount(data.filter((notification) => !notification.isRead).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  // Mark Single Notification as Read
  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/${notificationId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      });
      if (!response.ok) throw new Error("Failed to mark notification as read");
      // Update local state
      setNotifications((prev) =>
        prev.map((notification) =>
          notification._id === notificationId ? { ...notification, isRead: true } : notification
        )
      );
      setUnreadCount((prev) => Math.max(prev - 1, 0));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Mark All Notifications as Read
  const markAllAsRead = async () => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/mark-all/${user._id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      });
      if (!response.ok) throw new Error("Failed to mark all notifications as read");
      // Update all notifications to read locally
      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, isRead: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    if (user) fetchNotifications(); // Fetch notifications when the user logs in
  }, [user]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        loading,
        fetchNotifications,
        markNotificationAsRead,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

// Hook to use Notification Context
export const UseNotifications = () => useContext(NotificationContext);
